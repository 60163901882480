<template>
    <Component
        :is="componentInstance"
        v-bind="$attrs"
        :get-data-vuex="getDesigners"
        :placeholder="placeholder"
        :multiple="multiple"
        :searchable="false"
        :value="value"
        class="white"
        field="name"
        @[event]="$emit(event, $event)">
    </Component>
</template>
<script>
  import { mapActions, mapMutations, mapState } from "vuex";
  import { GET_DESIGNERS, SET_EMPTY } from "@core/store/action-constants";

  export default {
    name: "DesignerSelect",

    props: {
      value: {
        type: [Array, String],
        default: null
      },

      event: {
        type: String,
        default: "input"
      },

      placeholder: {
        type: String,
        default: ""
      },

      multiple: {
        type: Boolean,
        default: false
      },

      lazy: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      ...mapState("designersList", {
        designerList: ({ data }) => data
      }),

      componentInstance () {
        const name = this.lazy ? "LazySelect" : "Select";
        return () => import(`@/components/Common/Select/${ name }`);
      }
    },

    methods: {
      ...mapActions("designersList", {
        GET_DESIGNERS
      }),

      ...mapMutations("designersList", {
        SET_EMPTY
      }),

      async getDesigners () {
        await this.GET_DESIGNERS();
        const items = this.designerList;

        return {
          items,
          count: items.length
        };
      }
    },

    beforeDestroy () {
      this.SET_EMPTY();
    }
  };
</script>

<style scoped>

</style>